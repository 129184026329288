.whats {
    &-link {
        position: fixed;
        right: 1em;
        bottom: 6em;
        z-index: 10;
        width: 64px;
        height: 64px;
    }
}
