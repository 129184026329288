@use "../abstracts" as *;
@import "../../variables";

%textStyle {
    font-family: $font-main;
    font-size: size(text, 500);
    font-weight: 600;
    font-style: normal;
    text-align: center;
}

.nav {
    width: 100%;
    @extend %transitionDefault;

    &__list {
        width: 100%;

        &--drop {
            top: 100%;
            left: 0;
            z-index: 100;
            flex-direction: column !important;
            align-items: flex-start !important;
            justify-content: flex-start !important;
            @extend %transitionDefault;
        }
    }

    &__item {
        position: relative;
        color: color(primary, 300);
        padding: 0 0.5rem;
        cursor: default;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 3px;
            border-radius: 4px;
            background-color: color(primary, 300);
            @extend %transitionDefault;

            @include for-phone-only {
                height: 0;
            }
        }

        &:hover {
            color: color(primary);

            &::after {
                width: 100%;
            }
        }

        &:not(:last-of-type) {
            @include for-phone-only {}
        }

        &:not(.nav__item--drop-item) {
            padding: 0 0 0.5rem 1rem;

            @include for-phone-only {
                padding: 0;

                &:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: color(primary, 300);
                }
            }
        }

        &--drop {
            position: relative;

            span {
                @extend %textStyle;
                color: color(primary, 300);

                @include for-phone-only {
                    padding: 0 0 0.5rem 1rem;
                    color: color(primary, 300);
                    justify-content: flex-start !important;
                }
            }

            &-item {
                position: relative;
                width: 100%;

                a {
                    @include for-tablet-landscape-up {
                        padding: 0.5rem 0;
                    }
                }

                &::after {
                    background-color: color(primary);
                }

                @include for-phone-only {
                    &:not(:last-of-type)::after {
                        height: 1px;
                        width: 100%;
                    }
                }
            }

            &:hover {
                span {
                    @include for-phone-only {
                        color: color(primary, 400) !important;
                    }
                }

                &>ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &__link {
        color: color(primary, 300);
        width: 100%;
        height: 100%;
        @extend %textStyle;

        text-align: flex-start;

        @include flex(flex, column, center, center);

        @include for-phone-only {
            @include flex(flex, column, flex-start, center);
            padding: 0 0 0.5rem 1rem;
            color: color(primary, 300);

            &:hover {
                color: color(primary, 400);
            }
        }
    }

    @include for-phone-only {
        &__list {
            &--drop {
                a {
                    text-align: left;
                    color: color(primary, 300) !important;
                }
            }
        }
    }

    @include for-desktop-up {
        &__content {
            display: block;
        }

        &__list {
            @include flex(flex, row, center, space-between);

            &--drop {
                position: absolute;
                min-width: 300px;
                opacity: 0;
                visibility: hidden;
                background-color: white;
                box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.15);

                a {
                    align-items: flex-start;
                    color: color(primary, 300);

                    &:hover {
                        color: color(white, 100);
                    }
                }

                &-item {
                    &:hover {
                        background: color(primary, 300);
                    }
                }
            }
        }

        &__item {
            &--drop {
                &-item {
                    a {
                        align-items: flex-start;
                    }

                    &:not(:last-of-type) {
                        // border-bottom: 1px solid #e8e8e8;
                        margin-left: unset;
                    }

                    &:hover {
                        background: color(primary, 300);
                    }
                }
            }
        }

        &__item,
        &__link {
            height: 100%;
        }
    }
}

.nav-mobile {
    @extend %transitionDefault;

    position: fixed;
    top: 0;
    right: -240px;
    z-index: 100;
    width: 240px;
    height: 100%;
    background-color: color(white, 100);
    // padding: 0 0 0 2rem;

    &__overlay {
        @extend %transitionDefault;

        position: fixed;
        top: 0;
        right: -100%;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__close {
        text-align: right;
        font-size: size(text, 400);
        margin: 2rem 1rem 0 0;
        color: color(primary, 300);

        &:hover {
            cursor: pointer;
        }
    }

    &__button {
        color: color(primary, 300);
        width: 100%;
        font-size: size(text, 500);
        cursor: pointer;
    }

    @include for-desktop-up {
        position: static;
        width: 100%;
        background-color: transparent;

        &__button,
        &__close {
            display: none;
        }
    }

    &__active,
    &__active>&__overlay {
        right: 0;
    }
}