@use "./functions" as *;

%transitionDefault {
    transition: all 0.5s ease-in-out;
}

%boxShadowDefault {
    // box-shadow: 0 4px 40px -10px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 28px -10px rgba(0, 0, 0, 0.15);
}

%backgroundImagemDefault {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

%alignCenter {
    align-items: center;
    justify-content: center;
}

%buttonDefault {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // border: 1px solid color(primary) !important;
    border-radius: 50px;
    @extend %transitionDefault;
}

%scroll-bar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
