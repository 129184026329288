@use "../abstracts" as *;

.banner-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 193px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__figure {
    margin-bottom: 8px;
  }

  &__image {
    width: 0px;
  }

  &__title {
    font: 800 36px/32px "Lato", sans-serif;
    color: color(white, 100);
    text-align: center;

    @include for-phone-only {
      font-size: 28px;
    }
  }

  &__model {
    font: 400 40px/48px "Lato", sans-serif;
    color: #fff;
    text-align: center;

    @include for-phone-only {
      font-size: 40px;
    }
  }

  p {
    color: color(white, 100);
    font-size: size(text, 400);
    font-weight: bold;
  }

  .breadcrumb {

    &-item {
      color: color(white, 100);
      font-size: size(text, 400);

      &::before {
        color: color(white, 100);
      }

      @include for-phone-only {
        font-size: size(text, 200);
      }
    }
  }
}