@use "../abstracts" as *;
@import "../../variables";

.header {
    width: 100%;

    .top {
        background-color: color(primary, 300);
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 8px 0;

        @include for-phone-only {
            font-size: size(text, 200);
        }

        .list {
            &__item {
                position: relative;
                padding: 0 12px;

                @include for-tablet-landscape-up {
                    &:not(:last-of-type)::after {
                        content: "";
                        background: white;
                        width: 2px;
                        height: 12px;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                a {
                    color: color(white, 100);
                    font-size: size(text, 200);

                    &:hover {
                        color: color(white, 100);
                    }
                }

                @include for-phone-only {
                    &--border {
                        border-right: 2px solid white;
                    }
                }
            }
        }
    }

    &__section {
        background: color(white, 100);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
}