// Typography
$font-main: "Lato", sans-serif;

$type-scale-key: 300 !default;

$type-scale: (
    text: (
        100: 0.75rem,  //12px
        200: 0.875rem, //14px
        300: 1rem,     //16px
        400: 1.125rem, //18px
        500: 1.25rem,  //20px
    ),
    title: (
        100: 1.5rem,   //24px
        200: 1.875rem, //30px
        300: 2.25rem,  //36px
        400: 2.625rem, //42px
        500: 3.25rem,  //52px
    ),
);
