.image {
    img {
        max-width: 100%;
    }

    &-style-side {
        float: left;
        margin-right: 1rem;
    }
}
